<!-- 联系我们 -->
<template>
<div class="contentUs">
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <!--地图-->
    <div class="wCenter" v-if="config.c1&&config.c1.isShow">
        <div class="title">
            <!-- <span>
                <span class="txt">办公地点：</span>
                <span v-for="(item,index) in listTabs" :key="index" class="tab" :class="{'active':index==currTab}" @click="handleChangeTab(index)">{{item}}</span></span> -->
            <span @click="showModal=true" class="moreBtn">参展联络</span>
        </div>
        <div class="map" v-if="currTab==0">
            <div class="mapLeft" v-if="config.c2&&config.c2.isShow">
                <img :src="config.c2.src" width="100%" />
            </div>
            <div class="mapRight">
                <p class="compant" v-if="config.c3&&config.c3.isShow">{{config.c3.title||item.filialeName}}</p>
                <p class="address" v-if="config.c4&&config.c4.isShow"><img src="../../assets/contentUs1.png" />{{config.c4.address||item.filialeAddress}}</p>
                <p class="address" v-if="config.c5&&config.c5.isShow"><img src="../../assets/contentUs2.png" />{{config.c5.tel||item.tel}}</p>
                <p class="address" v-if="config.c6&&config.c6.isShow"><img src="../../assets/contentUs3.png" />
                <span v-if="config.c6&&config.c6.email"><a :href="'mailto:'+config.c6.email">{{config.c6.email}}</a></span>
                <span v-else><a :href="'mailto:'+item.email">{{item.email}}</a></span>
                </p>
            </div>
        </div>
        <!-- <div class="map" v-if="currTab==1">
            <div class="mapLeft">
                <img src="../../assets/map_shanghai.png" width="100%" />
            </div>
            <div class="mapRight">
                <p class="compant">上海办公室</p>
                <p class="address"><img src="../../assets/contentUs1.png" />上海市虹口区天潼路328号星荟中心WEWORK 04-178</p>
                <p class="address"><img src="../../assets/contentUs2.png" />+86 021–5330 8585</p>
            </div>
        </div>
        <div class="map" v-if="currTab==2">
            <div class="mapLeft">
                <img src="../../assets/map_hongkong.png" width="100%" />
            </div>
            <div class="mapRight">
                <p class="compant">香港办公室</p>
                <p class="address"><img src="../../assets/contentUs1.png" />大新行香港上環德輔道中181號8樓801室</p>
            </div>
        </div> -->
    </div>
    <!--地图-->
    <!--联系我们表单-->
    <!-- <div class="wCenter" v-if="config.c1&&config.c1.isShow">
        <div class="tableMain">
            <p class="titleMain">{{config.c1.title}}</p>
            <Form :model="form" :label-width="0" ref="form" :rules="ruleValidate">
                <FormItem prop="name">
                    <Input v-model="form.name" placeholder="请输入姓名" maxlength="50" />
                </FormItem>
                <FormItem prop="tel">
                    <Input v-model="form.tel" placeholder="请输入手机号码" maxlength="20" />
                </FormItem>
                <FormItem prop="email">
                    <Input v-model="form.email" placeholder="请输入邮箱" maxlength="50" />
                </FormItem>
                <FormItem prop="company">
                    <Input v-model="form.company" placeholder="请输入企业名称" maxlength="100" />
                </FormItem>
                <FormItem class="textarea" prop="consult">
                    <Input v-model="form.consult" placeholder="请输入咨询事宜" type='textarea' :rows="2" maxlength="255" />
                </FormItem>
                <FormItem class="submit">
                    <Button type="primary" @click='handleSubmit'>立即提交</Button>
                </FormItem>
            </Form>
        </div>
    </div> -->
    <!-- <div class="wCenter" v-if="config.c2&&config.c2.isShow&&list&&list.length">
        <p class="title1">{{config.c2.title}}</p>
        <div class="content">
            <div class="item" v-for="(item,index) in list" :key="index">
                <p class="title">{{item.filialeName}}</p>
                <p class="text" v-if="item.tel">
                    <img src="../../assets/contentUs2.png" /><a :href="'tel:'+item.tel" target="_blank">{{item.tel}}</a>
                </p>
                <p class="text" v-if="item.filialeAddress">
                    <img src="../../assets/contentUs1.png" />{{item.filialeAddress}}
                </p>
            </div>
        </div>
    </div> -->
    <!-- 弹框 -->
    <section v-show="showModal" class="formModal">
        <FormContact @cancel='showModal=false'></FormContact>
    </section>
</div>
</template>

<script>
import {
    getPageConfigByCode,
    getListContact,
    // leaveAskSubmit
} from '@/api/api'
import TopImg from '@/components/topImg'
// import Cookies from 'js-cookie'
import FormContact from '@/components/form-contact'
export default {
    name: 'contact',
    components: {
        TopImg,
        FormContact
    },
    data() {
        return {
            config: {},
            // list: [],
            currTab: 0,
            // value3: 0,
            // listTabs: ['北京', '上海', '香港'],
            showModal: false,
            item:{}
            // form: {
            //     name: '',
            //     email: '',
            //     company: '',
            //     consult: '',
            //     source: 1,
            //     tel: ''
            // },
            // ruleValidate: {
            //     name: [{
            //         required: true,
            //         message: '请填写姓名',
            //         trigger: 'blur'
            //     }],
            //     email: [{
            //         required: true,
            //         message: '请输入邮箱',
            //         trigger: 'blur'
            //     }, {
            //         validator: (rule, value, callback) => {
            //             const reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            //             if (value && !reg.test(value)) {
            //                 callback(new Error("邮箱格式不正确"));
            //             } else {
            //                 callback();
            //             }
            //         },
            //         trigger: "blur"
            //     }],
            //     company: [{
            //         required: true,
            //         message: '请输入企业名称',
            //         trigger: 'blur'
            //     }],
            //     consult: [{
            //         required: true,
            //         message: '请输入咨询事宜',
            //         trigger: 'blur'
            //     }],
            //     tel: [{
            //         required: true,
            //         message: '请输入手机号码',
            //         trigger: 'blur'
            //     }, {
            //         validator: (rule, value, callback) => {
            //             const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            //             if (value && !reg.test(value)) {
            //                 callback(new Error("手机号格式不正确"));
            //             } else {
            //                 callback();
            //             }
            //         },
            //         trigger: "blur"
            //     }],
            // },

        }
    },
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        handleChangeTab(index) {
            this.currTab = index
        },
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'contactUs/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                    let c2 = this.config.c2
                    if (c2 && c2.isShow) {
                        this.getList()
                    }
                }
            })
        },
        getList() {
            getListContact().then(res => {
                if (res.success) {
                    // this.list = res.result
                    this.item=res.result[0]
                }
            })
        },
        // handleSubmit() {
        //     this.$refs.form.validate((valid) => {
        //         if (valid) {
        //             leaveAskSubmit(this.form).then(res => {
        //                 if (res.success) {
        //                     this.$Message.success('提交成功!');
        //                     Cookies.set('isSubmit', '1', {
        //                         expires: 7
        //                     });
        //                     this.$refs.form.resetFields();
        //                 } else {
        //                     this.$Message.error('提交失败!');
        //                 }
        //             })
        //         }
        //     })
        // },
    },
}
</script>

<style lang="scss" scoped>
.title1 {
    color: #333333;
    font-size: 0.24rem;
    margin-top: .5rem;
}

.tableMain {
    background: #eeeeee;
    padding: 0.4rem 0.5rem;
    margin-top: 0.6rem;

    .title {
        color: #333333;
        font-size: 0.24rem;
        margin-bottom: 0.3rem;
        margin-top: 0;
    }

    .ivu-form {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 767px) {
            display: block;
        }
    }

    .ivu-form-item {
        width: 5.9rem;

        @media (max-width: 767px) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .product {
        width: 9.1rem;
    }

    .submit {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @media (max-width: 767px) {
            justify-content: center;
        }

        .ivu-btn-primary {
            margin-right: .1rem;
            width: 2rem;
            height: 0.5rem;
            background-color: #ff1932;
            border-color: #ff1932;

            @media (max-width: 767px) {
                width: 100%;
                height: 0.5rem * 1.5;
            }
        }
    }

    .ivu-form-item:nth-child(odd) {
        margin-right: 0.1rem;

        @media (max-width: 767px) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.titleMain {
    color: #333333;
    font-size: 0.3rem;
    margin: 0.2rem 0 0.2rem 0;

    @media (max-width: 767px) {
        margin: 0.2rem 0 0.2rem 0;
    }
}

.title {
    color: #333333;
    font-size: 0.3rem;
    margin: 0.3rem 0 0.2rem 0;
    display: flex;
    // justify-content: space-between;
    justify-content: flex-end;

    @media (max-width:767px) {
        margin: 0.8rem 0 0.2rem 0;
    }
    .txt{
        margin-right:.2rem;
    }

    .moreBtn {
        cursor: pointer;
        width: 2rem;
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        font-size: 0.22rem;
        color: #ffffff;
        background: #ff1932;
        border-radius: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #f00;

        @media (max-width:767px) {
            width: 3rem;
            height: 0.75rem;
            line-height: 0.75rem;
			font-size: 0.36rem;
        }
    }
}

.tab {
    margin-right: 0.5rem;
    cursor: pointer;
    font-size: .3rem;

    &.active {
        color: #ff1932;
        border-bottom: 2px solid #ff1932;
        padding-bottom: 0.1rem;
    }
}

.map {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;

    @media (max-width: 767px) {
        display: block;
    }

    .mapLeft {
        width: 6.8rem;

        @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 0.5rem;
        }
    }

    .mapRight {
        width: 6.2rem;
        padding-left: 1.2rem;

        @media (max-width: 767px) {
            width: 100%;
            padding-left: 0;
            padding-bottom: 0.2rem;
        }

        .compant {
            color: #333333;
            font-size: 0.22rem;
            margin-bottom: 0.1rem;
            font-weight: bold;
			@media (max-width:767px) {
				font-size: 0.38rem;
			}
        }

        .address {
            img {
                width: 0.3rem;
                margin-right: 0.1rem;
                position: relative;
                top: 0.1rem;
				@media (max-width:767px) {
					width: 0.4rem;
				}
            }

            color: #333333;
            font-size: 0.2rem;
            margin-bottom: 0.1rem;
			@media (max-width:767px) {
				font-size: 0.38rem;
			}
        }
    }

}

.content {
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;

    .item {
        width: 4rem;
        padding: 0.25rem 0.25rem;
        margin: 0 0.4rem 0.4rem 0;
        background: url('../../assets/contextBg.png');
        border-radius: 0.1rem;
        background-size: 100% 100%;

        &:nth-child(3n) {
            margin-right: 0;
        }

        @media (max-width: 767px) {
            $w: 49%;
            $gutter: (100% - $w * 2);
            width: $w;

            &,
            &:nth-child(3n) {
                margin: 0 $gutter $gutter 0;
            }

            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        .name {
            color: #333333;
            font-size: 0.18rem;
            margin: 0.1rem 0;
            font-weight: bold;
        }

        .title {
            color: #333333;
            font-size: 0.22rem;
            margin-bottom: 0.1rem;
            font-weight: bold;
        }

        .line {
            background-color: #333333;
            width: 0.5rem;
            height: 0.05rem;
            margin: 0.15rem 0;
        }

        .text {
            color: #333333;
            font-size: 0.18rem;
            margin-bottom: 0.1rem;

            a {
                color: inherit;
            }

            img {
                width: 0.22rem;
                position: relative;
                top: 0.03rem;
                margin-right: 0.1rem;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.contentUs {
    ::v-deep .ivu-input {
        height: 0.5rem !important;

        @media (max-width: 767px) {
            height: 0.5rem * 1.5 !important;
        }
    }

    .textarea {
        width: 100%;

        ::v-deep .ivu-input {
            height: 1rem !important;

            @media (max-width: 767px) {
                height: 0.5rem * 1.5 !important;
            }
        }
    }
}
</style>
