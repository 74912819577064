<!--表单-->
<template>
<div class="formContact">
    <Form :model="form" :label-width="100" ref="form" label-position="left"  :rules="ruleValidate">
        <FormItem prop="name" label="姓名：">
            <Input v-model="form.name" placeholder="请输入姓名" maxlength="50" />
        </FormItem>
        <FormItem prop="tel" label="手机：">
            <Input v-model="form.tel" placeholder="请输入手机号码" maxlength="20" />
        </FormItem>
        <FormItem prop="company" label="公司名称：">
            <Input v-model="form.company" placeholder="请输入公司名称" maxlength="100" />
        </FormItem>
        <FormItem prop="email" label="邮箱：">
            <Input v-model="form.email" placeholder="请输入邮箱" maxlength="50" />
        </FormItem>
        <FormItem class="textarea" prop="companyAddress" label="公司地址：">
            <Input v-model="form.companyAddress" placeholder="请输入公司地址" type='textarea' :rows="2" maxlength="255" />
        </FormItem>
        <FormItem class="textarea" prop="product" label="产品：">
            <Input v-model="form.product" placeholder="请输入产品" type='textarea' :rows="2" maxlength="255" />
        </FormItem>
        <div class="submit">
            <Button type="primary" class="btn qx" @click='cancel'>取消</Button>
            <Button type="primary" class="btn tj" @click='handleSubmit'>立即提交</Button>
        </div>
        <FormItem class="last" :labelWidth="0">
            <p class="tips">保护条款：我们一贯重视隐私权及公司信息的保护，为方便与您取得联系，我们会收集您/您公司的相关信息，我们不会将这些信息透露给任何第三方。</p>
        </FormItem>
    </Form>
</div>
</template>

<script>
import {
    leaveAskSubmit
} from '@/api/api'
import Cookies from 'js-cookie'
export default {
    name: "form-contact",
    data() {
        return {
            form: {
                name: '',
                tel: '',
                company: '',
                email: '',
                companyAddress:'',
                product: '',
                source: '0', // 0 首页、商旅信息 ，1 联系我们
            },
            ruleValidate: {
                name: [{
                    required: true,
                    message: '请填写姓名',
                    trigger: 'blur'
                }],
                tel: [{
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur'
                    },
                    {
                        validator: (rule, value, callback) => {
                            const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                            if (value && !reg.test(value)) {
                                callback(new Error("手机号格式不正确"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur"
                    }
                ],
                company: [{
                    required: true,
                    message: '请输入公司名称',
                    trigger: 'blur'
                }],
                email: [{
                    required: true,
                    message: '请输入邮箱',
                    trigger: 'blur'
                }, {
                    validator: (rule, value, callback) => {
                        const reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                        if (value && !reg.test(value)) {
                            callback(new Error("邮箱格式不正确"));
                        } else {
                            callback();
                        }
                    },
                    trigger: "blur"
                }],
                
                // product: [{
                //     required: true,
                //     message: '请输入产品',
                //     trigger: 'blur'
                // }],
            }
        }
    },
    mounted() {
        // 如果是联系我们页面source = "1" ,其他页面是 "0"
        if(this.$route&&this.$route.path=='/contact'){
            this.form.source='1'
        }
    },
    methods: {
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    leaveAskSubmit(this.form).then(res => {
                        if (res.success) {
                            this.$Message.success('提交成功!');
                            this.$emit('cancel')
                            Cookies.set('isSubmit', '1', {
                                expires: 7
                            });
                            this.$router.go(0)
                        } else {
                            this.$Message.error('提交失败!');
                        }
                    })
                }
            })
        },
        cancel() {
            this.$emit('cancel')
        }
    },
};
</script>
<style lang="scss">
    .ivu-form-item{
        .ivu-form-item-label{
            padding-top:.16rem;
        }
    }
</style>
<style lang="scss" scoped>
.formContact {
    ::v-deep .ivu-input {
        border-radius: 0;
        height: 0.5rem !important;

        @media (max-width: 767px) {
            height: 0.5rem * 1.5 !important;
        }
    }

    .submit {
        width: 100%;
        display: flex;
        justify-content: center;
		margin-bottom: 0.4rem;

        @media (max-width: 767px) {
            justify-content: center;
        }

        .btn {
            margin-right: .1rem;
            width: 2rem;
            height: 0.5rem;

            @media (max-width: 767px) {
                width: 100%;
                height: 0.5rem * 1.5;
            }

            &.qx {
                background-color: #000000;
                border-color: #000000;
            }

            &.tj {
                background-color: #ff1932;
                border-color: #ff1932;
            }
        }
    }

    .tips {
        line-height: 1.5em;
    }

    .last {
        margin-bottom: 0 !important;
    }
}
</style>
